import { ApolloClient, HttpLink } from "@apollo/client/core";
import { WebSocketLink } from "apollo-link-ws";
import { InMemoryCache } from "apollo-cache-inmemory";
import Vue from "vue";

export class BackendConnector {
  constructor(uri) {
    if (uri.startsWith("ws")) {
      this.createWsClient(uri);
    } else {
      this.createHttpClient(uri);
    }
    console.log("backend connector created");
  }

  createWsClient(uri) {
    this.link = new WebSocketLink({
      uri: uri,
      options: {
        reconnect: true,
        timeout: 30000,
        connectionParams: () => ({
          headers: {
            authorization: "Bearer " + Vue.$keycloak.token,
            "x-player-id": parseInt(
              decodeURIComponent(document.location.pathname.split("/user/")[1])
            ),
          },
        }),
      },
    });

    const defaultOptions = {
      watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
      },
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      },
    };

    this.client = new ApolloClient({
      link: this.link,
      cache: new InMemoryCache({
        addTypename: true,
      }),
      defaultOptions: defaultOptions,
    });
  }

  createHttpClient(uri) {
    this.link = new HttpLink({
      uri: uri,
    });

    const defaultOptions = {
      watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
      },
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      },
    };

    this.client = new ApolloClient({
      link: this.link,
      cache: new InMemoryCache(),
      defaultOptions: defaultOptions,
      request: async (operation) => {
        operation.setContext({
          headers: {
            authorization: "Bearer " + Vue.prototype.$keycloak.token,
          },
        });
      },
    });
  }

  async disconnect() {
    await this.client.clearStore();
    this.client.stop();
    if (this.link.subscriptionClient) {
      this.link.subscriptionClient.close();
    }
    console.log("backend connector disconnected");
  }
}
