const state = {
  controls: {
    acceleration: {
      modifier: 1,
    },
    deadZone: 35,
  },
};
const getters = {
  getAccelerationModifier: (state) => state.controls.acceleration.modifier,
  getDeadZone: (state) => state.controls.deadZone,
};
const actions = {};
const mutations = {
  setAccelerationModifier(state, modifier) {
    console.log("set accelerator modifier value ", modifier);
    state.controls.acceleration.modifier = modifier;
  },
  setDeadZone(state, deadZone) {
    console.log("set deadZonevalue ", deadZone);
    state.controls.deadZone = deadZone;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
