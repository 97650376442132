<template>
  <div id="main" class="home">
    <div id="controls" style="display: none">
      <Control
        :controlMode="controlChoosedR"
        :color="{
          color1: '--color-slider-right',
          color2: '--slider-suplement-color-right',
          color3: '--slider-detail-right',
        }"
        :isAcceleration="true"
      >
      </Control>
      <Control
        :controlMode="controlChoosedL"
        :color="{
          color1: '--color-slider-left',
          color2: '--slider-suplement-color-left',
          color3: '--slider-detail-left',
        }"
        :isAcceleration="false"
      >
      </Control>
    </div>

    <!-- <div v-if="!wasClicked" class="pressMe" v-on:click="clickedPressMe"></div> -->
    <!-- <div
      v-if="!isDriver && !isSpectator && wasClicked && !connectionInit"
      class="noRace"
    ></div> -->
    <div v-if="!isDriver && !isSpectator && wasClicked && !connectionInit">
      <TransitionPage
        :isButton="false"
        :hasHeader="false"
        message="No Race"
        :circleColor="colorValue(color.colorBigCircle)"
        :info="this.$t('messages.noRace')"
      />
    </div>
    <!-- <div v-if="connectionInit" class="loading"></div> -->
    <div v-if="connectionInit">
      <TransitionPage
        :isButton="false"
        :hasHeader="false"
        message="Loading"
        :circleColor="colorValue(color.colorBigCircle)"
      />
    </div>
    <!-- <div
      v-if="
        !connectionInit &&
        isDriver &&
        !raceOngoing &&
        wasClicked &&
        !(displayVersusResult || displayRanking)
      "
      class="waitingForOtherPlayers"
    >
      <v-row style="height: 100%">
        <v-spacer />
        <v-col cols="6" class="result">
          <div>
            <p>GET READY WHILE OTHER PLAYERS JOIN THE RACE</p>
          </div>
        </v-col>
        <v-spacer />
      </v-row>
    </div> -->
    <div
      v-if="
        !connectionInit &&
        isDriver &&
        !raceOngoing &&
        wasClicked &&
        !(displayVersusResult || displayRanking)
      "
    >
      <TransitionPage
        :isButton="false"
        :hasHeader="false"
        :circleColor="colorValue(color.colorBigCircle)"
        message="GET READY WHILE OTHER PLAYERS JOIN THE RACE"
      />
    </div>
    <div class="layer-result" v-if="displayVersusResult || displayRanking">
      <v-row v-if="displayRanking" style="height: 100%">
        <v-spacer />
        <v-col cols="6" class="result">
          <div v-if="raceMode === 'Versus' && ranking !== undefined">
            <p>Your position</p>
            <h2>{{ rankToStr(ranking) }}</h2>
            <v-btn class="yellow-btn" @click="goToVersusResult()">Next</v-btn>
          </div>
          <div v-if="raceMode === 'Versus' && ranking === undefined">
            <p>The race just finished</p>
            <v-btn class="yellow-btn" @click="goToVersusResult()">
              Display results
            </v-btn>
          </div>
          <div v-if="raceMode === 'RaceAsYouGo'" class="result-raceasyougo">
            <h2>Congratulations {{ playerName }}!</h2>
            <h2>The race is over</h2>
            <p style="margin-bottom: 0px">&nbsp;</p>
            <p style="margin-bottom: 0px">
              Your race time: {{ raceTotalTimeStr }}
            </p>
            <p style="margin-bottom: 0px">Your best lap: {{ bestLapStr }}</p>
            <p style="margin-bottom: 0px">&nbsp;</p>
            <v-row>
              <v-col>
                <v-btn class="white-btn" @click="nextPlayer()"
                  >Next Player</v-btn
                >
                &nbsp;
                <v-btn class="yellow-btn" @click="retryRacing()">Retry</v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-spacer />
      </v-row>
      <div v-if="displayVersusResult" style="width: 100%; height: 100%">
        <v-row>
          <v-col
            style="
              border: 2px solid;
              background-color: var(--backgroundColorBar);
            "
            ><h1>Race Result</h1></v-col
          ></v-row
        >
        <v-row>
          <v-col>
            <v-data-table
              style="margin: 30px; font-size: 2em"
              :headers="resultsHeaders"
              :items="resultsPlayers"
              :disable-filtering="true"
              :disable-pagination="true"
              :disable-sort="true"
              :hide-default-footer="true"
            ></v-data-table></v-col
        ></v-row>
        <v-row>
          <v-col>
            <v-btn class="white-btn" @click="nextPlayer()">Next Player</v-btn>
            &nbsp;
            <v-btn class="yellow-btn" @click="retryRacing()">Retry</v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
    <GameUi
      v-if="isDriver || isSpectator"
      :isDriver="isDriver"
      :isSpectator="isSpectator"
      :waitListPosition="waitListPosition"
      :raceMode="raceMode"
      :player="playerName"
      :laps="laps"
      :currentLap="currentLap"
      :numLaps="numLaps"
      :startTimeMs="startTimeMs"
      :isTelevision="isTelevision"
      :localTimeMsAhead="localTimeMsAhead"
      v-on:race-start="setRaceStarted()"
      v-on:boost="boostActivation"
    />
    <div id="video"></div>
  </div>
</template>

<script>
import TransitionPage from "@/components/TransitionScreen.vue";
import { mapState } from "vuex";
import GameUi from "@/components/GameUi.vue";
import gql from "graphql-tag";
import { RoomEvent } from "livekit-client";
import { Room } from "livekit-client";
import { DataPacket_Kind } from "livekit-client";
import { BackendConnector } from "../backend_connector";
import Vue from "vue";
import { ordinal, msToString, bestLap, sumLaps } from "../tools";
import Control from "@/components/controls/Controller.vue";
import getEnv from "@/config/env.js";
import Subscriptions from "../services/api/subscriptions.js";

export default {
  name: "Game",
  data() {
    return {
      isDebug: false,
      controlChoosedR: "4",
      controlChoosedL: "5",
      currentSpeed: null,
      boostMax: 1.25,
      boostVal: 1,
      localTimeMsAhead: 0,
      wasClicked: false,
      room: null,
      isDriver: false,
      isSpectator: false,
      raceOngoing: false,
      leftNipple: null,
      rightNipple: null,
      waitListPosition: 0,
      raceMode: "Versus",
      playerName: "Wayra",
      playersName: {},
      laps: [],
      currentLap: 0,
      numLaps: 3,
      numPlayers: 0,
      startTimeMs: 0,
      displayRanking: false,
      displayVersusResult: false,
      connectionInit: false,
      raceStarted: false,
      resultsHeaders: [
        { text: "Position", value: "position" },
        { text: "Player", value: "player" },
        { text: "Best lap", value: "bestLap" },
        { text: "Time", value: "time" },
      ],
      resultsPlayers: [
        // {
        //   position: 1,
        //   player: "Michaël",
        //   bestLap: "00:20:126",
        //   time: "01:32:452",
        // },
      ],
    };
  },
  props: {
    color: {
      type: Object,
      default: () => ({
        colorBigCircle: "--loading-circle-color",
      }),
    },
  },
  components: {
    GameUi,
    Control,
    TransitionPage,
  },
  computed: {
    ...mapState(["isFold3", "isTelevision"]),
    ...mapState("Auth", ["pseudo"]),
    raceTotalTime: function () {
      return sumLaps(this.laps);
    },
    raceTotalTimeStr: function () {
      return msToString(this.raceTotalTime);
    },
    bestLapStr: function () {
      return msToString(bestLap(this.laps));
    },
    playerId: function () {
      return parseInt(document.location.pathname.split("/user/")[1]);
    },
    boostValUp: function () {
      const boost = this.boostVal;
      return boost;
    },
  },
  beforeCreate() {
    if (Vue.$room) {
      Vue.$room.disconnect();
    }
  },
  async created() {
    if (Vue.$carsBackendConnector) {
      Vue.$carsBackendConnector.disconnect();
      Vue.$carsBackendConnector = undefined;
    }

    if (Vue.$raceBackendConnector) {
      Vue.$raceBackendConnector.disconnect();
      Vue.$raceBackendConnector = undefined;
    }

    if (Vue.$usersBackendConnector) {
      Vue.$usersBackendConnector.disconnect();
      Vue.$usersBackendConnector = undefined;
    }

    Vue.$carsBackendConnector = new BackendConnector(
      getEnv("VUE_APP_CAR_BACKEND_URI")
    );
    Vue.$raceBackendConnector = new BackendConnector(
      getEnv("VUE_APP_RACE_BACKEND_URI")
    );
    Vue.$usersBackendConnector = new BackendConnector(
      getEnv("VUE_APP_USERS_BACKEND_URI")
    );

    this.resetParameters();

    Vue.$room = new Room({
      adaptiveStream: false,
      dynacast: false,
    });

    if (!this.isTelevision) {
      document.addEventListener(
        "visibilitychange",
        this.visibilityChangedCallback,
        { once: true }
      );
    }
  },

  mounted: function () {
    this.updatePlayerName();
    this.clickedPressMe();
    this.heightFullScreen();
  },
  watch: {
    isDebug(newVal) {
      if (newVal) {
        this.isDriver = true;
        this.raceOngoing = true;
        this.displayControls();
      } else {
        this.isDriver = false;
        this.raceOngoing = false;
        this.hideControls();
      }
    },
  },
  // beforeDestroy: function () {
  //   this.hideControls();
  //   if (this.leftNipple) {
  //     this.leftNipple.destroy();
  //     this.leftNipple = null;
  //   }
  //   if (this.rightNipple) {
  //     this.rightNipple.destroy();
  //     this.rightNipple = null;
  //   }
  // },
  methods: {
    heightFullScreen() {
      let containerHeight;
      const controlsElement = document.getElementById("controls");
      if (this.$store.state.isFold3) {
        console.log("fold controls");
        containerHeight = this.$store.state.screenHeight + "px";
      } else {
        console.log("normal controls");
        containerHeight = "100vh";
      }
      controlsElement.style.height = containerHeight;
      return containerHeight;
    },
    phoneVibration() {
      let duration = 1000;
      if ("vibrate" in navigator) {
        navigator.vibrate(duration);
        console.log("vibrate: ", duration, " ms");
      } else if ("mozVibrate" in navigator) {
        navigator.mozVibrate(duration);
        console.log("mozVibrate", duration, " ms");
      } else {
        console.log("vibration is not compatible with your device");
      }
    },
    colorValue(name) {
      return window
        .getComputedStyle(document.documentElement)
        .getPropertyValue(name);
    },
    boostActivation: function (activation) {
      if (activation) {
        this.boostVal = this.boostMax;
        console.log(activation, "boost val is = ", this.boostVal);
      } else {
        this.boostVal = 1;
        console.log(activation, "boost val is = ", this.boostVal);
      }
      this.sendSpeed(
        Math.floor(
          this.currentSpeed * 15 * this.boostVal // this.$store.state.carData.speedCalibration
        )
      );
    },
    sendLatencyTestMessage: function (latencyTestId) {
      Vue.$room.localParticipant.publishData(
        new TextEncoder().encode(
          JSON.stringify({
            pong: latencyTestId,
          })
        ),
        DataPacket_Kind.RELIABLE
      );
    },
    visibilityChangedCallback: async function () {
      if (document.visibilityState === "hidden") {
        this.resetParameters();
        this.disconnectFromCar();
        this.$router.replace({ path: "/" });
      }
    },
    updatePlayerName: async function () {
      // const playerStats = await this.getPlayerStats(this.playerId);
      this.playerName = "Wayra";
      // console.log("playerStats = ", playerStats);

      // if (playerStats) {
      //   this.playerName = playerStats.name;
      //   console.log("player name = ", this.playerName);
      // } else {
      //   this.playerName = `player ${this.playerId}`;
      // }
    },
    resetParameters: function () {
      console.log(`reset parameters`);
      this.wasClicked = false;
      this.isDriver = false;
      this.isSpectator = false;
      this.raceOngoing = false;
      this.waitListPosition = 0;
      this.laps = [];
      this.currentLap = 0;
      this.startTimeMs = 0;
      this.displayRanking = false;
      this.displayVersusResult = false;
      this.connectionInit = false;
    },
    displayControls: function () {
      document.querySelector("#controls").style.display = "block";
    },

    hideControls: function () {
      document.querySelector("#controls").style.display = "none";
    },
    clickedPressMe: function () {
      this.subscribeToCarCollision();
      this.subscribeToWaitList();
      this.subscribeToStartRace();
      this.subscribeToRaceEnded();
      this.subscribeToLaps();
      this.subscribeToStream();
      this.wasClicked = true;
      this.connectionInit = true;
      console.log(this.connectionInit, this.wasClicked);
      setTimeout(() => {
        this.connectionInit = false;
      }, 4000);
    },
    updateLocalTimeDelay(remoteTimeMs) {
      const localTimeMs = Date.now();
      this.localTimeMsAhead = localTimeMs - remoteTimeMs;
    },
    async disconnectFromCar() {
      try {
        await Vue.$carsBackendConnector.disconnect();
        await Vue.$raceBackendConnector.disconnect();
        await Vue.$usersBackendConnector.disconnect();
        Vue.$room.disconnect();
      } catch (error) {
        console.warn(error);
      }
    },
    rankToStr(rank) {
      return ordinal(rank);
    },
    resetRace() {
      this.laps = [];
      this.currentLap = 0;
    },
    addLap(lapDurationMs, ranking) {
      this.laps.push(lapDurationMs);
      this.ranking = ranking;
      if (this.laps.length < this.numLaps) {
        this.currentLap = this.laps.length + 1;
      } else {
        this.finishRace();
      }
    },
    subscribeToWaitList: function () {
      Vue.$carsBackendConnector.client
        .subscribe({
          query: Subscriptions.WAIT_LIST_SUBSCRIPTION,
        })
        .subscribe(
          ({ data }) => {
            this.$root.$emit("waitListPosition", data.waitList.queue_position);
            this.waitListPosition = data.waitList.queue_position;
          },
          (err) => {
            console.error(err);
          }
        );
    },
    subscribeToStartRace() {
      Vue.$carsBackendConnector.client
        .subscribe({
          query: gql`
            subscription RaceStart {
              raceStarted {
                startTimestamp {
                  timestamp
                  ms
                }
                raceMode
                numberLaps
                numberPlayers
                now {
                  timestamp
                  ms
                }
              }
            }
          `,
        })
        .subscribe(({ data }) => {
          console.log("Received start data: ", data);
          if (this.displayVersusResult || this.displayRanking) {
            this.resetParameters();
            this.disconnectFromCar();
            this.$router.replace({ path: "/" });
            return;
          }
          if (data.raceStarted.raceMode === "Versus") {
            this.raceStarted = false;
          } else {
            this.raceStarted = true;
          }
          const remoteTimeMs =
            data.raceStarted.now.timestamp * 1000 + data.raceStarted.now.ms;
          this.updateLocalTimeDelay(remoteTimeMs);
          const startTimeMs =
            data.raceStarted.startTimestamp.timestamp * 1000 +
            data.raceStarted.startTimestamp.ms;
          this.prepareRace(
            data.raceStarted.raceMode,
            startTimeMs,
            data.raceStarted.numberLaps,
            data.raceStarted.numberPlayers
          );
        });
      console.log("start data subscription OK");
    },
    setRaceStarted: function () {
      this.raceStarted = true;
    },
    subscribeToRaceEnded() {
      Vue.$carsBackendConnector.client
        .subscribe({
          query: gql`
            subscription RaceEnded {
              raceEnded
            }
          `,
        })
        .subscribe(() => {
          console.log("Received race ended");
          if (this.raceOngoing) {
            this.finishRace();
          }
        });
      console.log("race ended subscription OK");
    },
    subscribeToLaps() {
      Vue.$carsBackendConnector.client
        .subscribe({
          query: gql`
            subscription Lap {
              lap {
                carId
                playerId
                lapDurationMs
                raceDurationMs
                lapNumber
                numberLapsInRace
                ranking
                numberPlayers
              }
            }
          `,
        })
        .subscribe(({ data }) => {
          console.log("Received lap data: ", data);
          const lap = data.lap;
          if (lap.playerId !== this.playerId) {
            console.log(`lap of another player: ${lap.playerId}`);
            return;
          }
          if (lap.lapNumber === 0) {
            if (this.raceMode === "RaceAsYouGo") {
              this.startTimeMs = Date.now() - this.localTimeMsAhead;
              this.currentLap = lap.lapNumber + 1;
            } else {
              console.log(
                `playerId ${lap.playerId} crossed the line for the 1st time in versus mode`
              );
            }
          } else {
            this.addLap(lap.lapDurationMs, lap.ranking);
          }
        });

      console.log("start laps subscription OK");
    },
    subscribeToCarCollision: function () {
      Vue.$carsBackendConnector.client
        .subscribe({
          query: Subscriptions.CAR_COLLISION,
        })
        .subscribe(
          ({ data }) => {
            console.log("Collision: ", data);
            this.phoneVibration(); //if send information -> call this function
          },
          (err) => {
            console.error("Error", err);
          }
        );
      console.log("car collision suscribed");
    },
    subscribeToStream: function () {
      Vue.$carsBackendConnector.client
        .subscribe({
          query: Subscriptions.STREAM_SUBSCRIPTION,
        })
        .subscribe(
          ({ data }) => {
            console.log("Received Stream: ", data);
            console.log(`Stream type: ${JSON.stringify(data.stream.type)}`);
            this.manageNewVideoStream(data.stream);
          },
          (err) => {
            console.error("Error", err);
          }
        );
      console.log("STREAM suscribed");
    },

    manageNewVideoStream: function (stream) {
      if (Vue.$room.state === "connected") {
        Vue.$room.disconnect();
      }
      if (stream.type === "NONE") {
        this.isDriver = false;
        this.isSpectator = false;

        this.hideControls();
        return;
      }
      // Let's update the local data
      const videoContainer = document.querySelector("#video");
      Vue.$room.connect(stream.url, stream.token, {
        autoSubscribe: true,
      });
      Vue.$room.on(RoomEvent.TrackSubscribed, (track) => {
        const video = track.attach();
        if (!this.isTelevision) {
          if (this.isFold3) {
            video.style.cssText = "top: -38px; object-fit:contain;";
          } else {
            video.style.cssText = "bottom: 0; object-fit:contain;";
          }
        } else {
          video.style.cssText =
            "top: 0; min-height: 100vh; min-width: 100vw; object-fit: cover; object-position: center center";
        }
        videoContainer.appendChild(video);
        console.log("new video displayed");
        if (stream.type === "DRIVER") {
          this.isDriver = true;
          console.log("Driver Connected");
          this.isSpectator = false;
          this.$root.$emit("isDriver", true);
          this.screenH = screen.height;
          this.screenW = screen.width;
          this.displayControls();
        } else {
          this.isDriver = false;
          this.isSpectator = true;
          this.hideControls();
        }
      });
      Vue.$room.on(RoomEvent.TrackUnsubscribed, (track) => {
        console.log(`Track unsubscribed: ->  ${JSON.stringify(track)}`);
        videoContainer.replaceChildren();
        this.hideControls();
      });
      Vue.$room.on(RoomEvent.DataReceived, (data) => {
        // console.log(data, participant, room);
        let parsedData = JSON.parse(new TextDecoder().decode(data));
        if (parsedData.ping && this.isDriver) {
          // reply with pong
          this.sendLatencyTestMessage(parsedData.ping);
        }
      });
    },
    async retryRacing() {
      await Vue.$carsBackendConnector.client
        .mutate({
          mutation: gql`
            mutation retryRacing {
              retryRacing
            }
          `,
        })
        .then((result) => console.log(JSON.stringify(result)))
        .catch((e) => console.log(JSON.stringify(e)));
      this.resetRace();
      this.displayVersusResult = false;
      this.displayRanking = false;
      // start race should be sent by cars manager
    },
    async nextPlayer() {
      this.displayVersusResult = false;
      this.displayRanking = false;
      this.resetParameters();
      this.$store.state.isRegistered = false;
      await new Promise((resolve) => {
        this.$router.replace({ path: "/" });
        resolve();
        Vue.$carsBackendConnector.disconnect();
        Vue.$raceBackendConnector.disconnect();
        Vue.$usersBackendConnector.disconnect();
        Vue.$room.disconnect();
      });
    },

    goToVersusResult() {
      this.displayVersusResult = true;
      this.displayRanking = false;
    },
    goToRanking() {
      this.displayVersusResult = false;
      this.displayRanking = true;
      this.getRaceResult();
    },
    finishRace() {
      this.raceOngoing = false;
      this.raceStarted = false;
      if (this.isDriver) {
        this.goToRanking();
      }
      this.currentSpeed = 0;
      // this.sendSpeed(0);
      // this.sendDirection(0);
    },
    prepareRace(raceMode, startTimeMs, numLaps, numPlayers) {
      this.raceMode = raceMode;
      this.numLaps = numLaps;
      this.numPlayers = numPlayers;
      this.raceOngoing = true;
      if (this.raceMode === "RaceAsYouGo") {
        console.log("RaceAsYouGo mode");
        this.currentLap = 0;
        this.startTimeMs = 0;
      } else {
        console.log("Versus mode");
        this.startTimeMs = startTimeMs;
        this.currentLap = 1;
      }
    },
    async getRaceResult() {
      try {
        const result = await Vue.$raceBackendConnector.client.query({
          query: gql`
            query GetRaceResult {
              getRaceResult {
                raceMode
                numLaps
                arrivedCars {
                  carId
                  playerId
                  lapsDurationMs
                  ranking
                }
              }
            }
          `,
        });
        console.log(`arrived cars: ${JSON.stringify(result)}`);
        const arrivedCars = result.data.getRaceResult.arrivedCars;
        const results = [];
        for (const car of arrivedCars) {
          if (
            !Object.prototype.hasOwnProperty.call(
              this.playersName,
              car.playerId
            )
          ) {
            // const playerStats = await this.getPlayerStats(car.playerId);
            // if (playerStats) {
            //   this.playersName[car.playerId] = playerStats.name;
            // } else {
            //   this.playersName[car.playerId] = `player ${car.playerId}`;
            // }
          }
          results.push({
            position: car.ranking,
            player: this.playersName[car.playerId],
            bestLap: msToString(bestLap(car.lapsDurationMs)),
            time: msToString(sumLaps(car.lapsDurationMs)),
          });
        }
        this.resultsPlayers = results;
        console.log(`resultsPlayers: ${this.resultsPlayers}`);
      } catch (e) {
        console.log(JSON.stringify(e));
      }
      if (this.displayRanking || this.displayVersusResult) {
        setTimeout(this.getRaceResult, 1000);
      }
    },
    async getPlayerStats(id) {
      console.log(`get player details`);
      try {
        const result = await Vue.$usersBackendConnector.client.query({
          query: gql`
            query GetPlayerStats($id: ID!) {
              getPlayerStats(id: $id) {
                id
                name
                dayRank
                globalRank
                bestLap
                bestTime
                initialized
              }
            }
          `,
          variables: {
            id: id,
          },
        });
        console.log(`player stats: ${JSON.stringify(result)}`);
        const playerStats = result.data.getPlayerStats;
        if (!playerStats) {
          console.warn(`player id ${id} unknown`);
        }
        return playerStats;
      } catch (e) {
        console.log(JSON.stringify(e));
      }
    },
  },
};
</script>
<style scoped>
@import "../assets/css/views/Game.css";
</style>
