<template>
  <v-app>
    <v-main>
      <div id="mainWrap">
        <div class="installPopup">
          <v-snackbar
            v-if="showInstallMessage"
            :timeout="-1"
            :value="true"
            tile
          >
            <div>
              Install this webapp on your Phone to fully enjoy vrombr experience
              :
            </div>
            <div>
              tap
              <span
                ><img class="ios-share-img" src="../public/img/ios-share.png"
              /></span>
              and then Add to homescreen
            </div>
            <template v-slot:action="{ attrs }">
              <v-btn
                color="indigo"
                text
                v-bind="attrs"
                @click="showInstallMessage = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </div>
        <div v-if="nonCompatible" id="non-compatible-warning">
          <div class="warning-message">
            Vrombr is only supported in Landscape mode
          </div>
        </div>
        <div id="app">
          <v-snackbar
            bottom
            right
            :value="updateExists"
            :timeout="-1"
            color="primary"
          >
            An update is available
            <v-btn text @click="refreshApp"> Update </v-btn>
          </v-snackbar>
          <router-view />
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<style>
@import "./assets/css/App.css";
</style>

<script>
import Vue from "vue";
import update from "./mixins/update";
import uaParser from "ua-parser-js";
import getEnv from "./config/env.js";

//IMPORT THE CORRECT FILE CSS
let cssFileName = "";
console.log("value - " + getEnv("VUE_APP_VROMBR"));
if (getEnv("VUE_APP_VROMBR") === "B2B") {
  cssFileName = "globalB2B.css";
  console.log("B2B");
} else {
  cssFileName = "global.css";
  console.log("Hello Vrombr");
}
import(`./assets/css/${cssFileName}`);

export default {
  data() {
    return {
      showInstallMessage: false,
      nonCompatible: false,
      displayMode: null,
      screenHe: screen.height,
      screenWi: screen.width,
    };
  },
  components: {},
  mixins: [update],
  created() {
    //is Fold?
    if (
      this.screenHe / this.screenWi < 1.27 &&
      this.screenHe / this.screenWi > 0.77
    ) {
      this.$store.state.isFold3 = true;
    } else {
      this.$store.state.isFold3 = false;
    }
    console.log("is Fold 3 size like -", this.$store.state.isFold3);

    //Orientation Event
    let portrait = window.matchMedia("(orientation: portrait)");
    portrait.addEventListener("change", (e) => {
      console.log("orientation event : portrait");
      if (e.matches) {
        // Portrait mode
        if (this.$store.state.isInside && this.$store.state.isFold3) {
          this.nonCompatible = false;
          console.log("sign in using fold");
        } else {
          this.nonCompatible = true;
          console.log("sign in ");
        }
      } else {
        // Landscape
        this.nonCompatible = false;
      }
    });
    // window.addEventListener(
    //   "orientationchange",
    //   () => {
    //     this.orientationCompatibility();
    //   },
    //   false
    // );
  },
  beforeMount() {
    var parser = new uaParser();
    var userAgent = parser.getResult();
    console.log(userAgent);
    console.log(userAgent.os.name);
    // const screenHe = screen.height;
    // const screenWi = screen.width;
    this.$store.state.screenHeight = window.innerHeight;
    const mqStandAlone = "(display-mode: standalone)";
    if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
      this.displayMode = "standalone";
      console.log("standalone = ", this.standalone);
    }
    if (
      userAgent.os.name.toLowerCase() === "ios" &&
      this.displayMode !== "standalone"
    ) {
      this.showInstallMessage = true;
    }

    this.$store.carName = Vue.$keycloak.tokenParsed.preferred_username;
    if (Vue.$keycloak.hasResourceRole("drive")) {
      this.$store.state.isTelevision = false;
      console.log("drive");
    } else if (Vue.$keycloak.hasResourceRole("spectate")) {
      this.$store.state.isTelevision = true;
      console.log("spectate");
    }
  },
  async mounted() {
    this.orientationCompatibility();
    console.log(" is fold?    " + this.$store.state.isFold3);
  },
  methods: {
    orientationCompatibility() {
      var orientation = screen.width > screen.height ? "Landscape" : "Portrait";

      console.log("no es fold");
      if (orientation == "Portrait") {
        if (this.$store.state.isInside && !this.$store.state.isFold3) {
          this.nonCompatible = true;
          console.log("orientation is ", orientation);
          console.log(
            "width and height ",
            window.innerWidth,
            window.innerHeight
          );
          console.log("orientation is ", this.nonCompatible);
        } else {
          this.nonCompatible = false;
          this.$store.state.screenHeight = window.innerHeight;
        }
      } else {
        console.log("orientation is ", orientation);
        console.log("width and height ", window.innerWidth, window.innerHeight);
        this.$store.state.screenHeight = window.innerHeight;
        console.log("orientation is ", this.nonCompatible);
        this.nonCompatible = false;
      }
    },
  },
};
</script>
