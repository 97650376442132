<template>
  <div :style="this.cssVars()" class="transitionScreen">
    <button
      v-if="isCharging"
      class="backButton"
      @click="goBackButtonPressed"
      @touchstart="goBackButtonPressed"
    >
      <img src="../assets/images/left.png" alt="back icon" />
      <p>{{ $t("actions.goBack") }}</p>
    </button>
    <div id="transition-logo" class="transition-container unselectable">
      <logo></logo>
    </div>
    <div v-if="info" class="message">{{ $t("messages.noRace") }}</div>
    <div class="transition-container">
      <div v-if="!isButton" id="spinner"></div>
      <div
        id="transition-circle-button"
        class="circle"
        @click.stop="buttonClicked()"
      >
        <div class="text unselectable">{{ this.message }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Spinner } from "spin.js";
import { mapActions } from "vuex";
import logo from "../components/logo.vue";
export default {
  name: "TransitionPage",
  data() {
    return {
      opts: {
        lines: 40, // The number of lines to draw
        length: 1, // The length of each line
        width: 3, // The line thickness
        radius: 30, // The radius of the inner circle
        scale: null, // Scales overall size of the spinner
        corners: 1, // Corner roundness (0..1)
        speed: 0.7, // Rounds per second
        // rotate: 0, // The rotation offset
        animation: "spinner-line-shrink", // The CSS animation name for the lines
        direction: 1, // 1: clockwise, -1: counterclockwise
        color: "#000000", // CSS color or array of colors
        fadeColor: "transparent", // CSS color or array of colors
        // top: '50%', // Top position relative to parent
        // left: '50%', // Left position relative to parent
        shadow: "0 0 1px transparent", // Box-shadow for the lines
        zIndex: 20, // The z-index (defaults to 2e9)
        className: "spinner", // The CSS class to assign to the spinner
        // position: 'relative', // Element positioning
      },
      isMessage: false,
      logoContainerSize: null,
      pseudoB2b: "",
      isCharging: false,
    };
  },
  props: {
    circleColor: String,
    isButton: Boolean,
    hasHeader: Boolean,
    message: String,
    info: String,
  },
  components: {
    logo,
  },
  mounted() {
    var buttonSize = document.getElementById(
      "transition-circle-button"
    ).clientWidth;
    var logoSize = document.getElementById("transition-logo").clientHeight;
    this.logoContainerSize = logoSize;
    this.opts.scale = (buttonSize * 2.15) / 230;
    this.opts.color = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue("--secondary-color");
    var target = document.getElementById("spinner");
    new Spinner(this.opts).spin(target);
  },
  methods: {
    goBackButtonPressed() {
      this.$router.replace({ path: "/" });
    },
    cssVars: function () {
      let buttonCirclePath;
      let containerHeight;
      let logoCenter;
      let circleNormal = this.circleColor;

      if (this.isButton) {
        buttonCirclePath =
          "%3Cpath class='cls-1' d='m372.66 234.9c36.83 0 71.46 14.34 97.51 40.39 26.04 26.04 40.39 60.67 40.39 97.51s-14.34 71.46-40.39 97.51c-26.04 26.04-60.67 40.39-97.51 40.39s-71.46-14.34-97.51-40.39c-26.04-26.04-40.39-60.67-40.39-97.51s14.34-71.46 40.39-97.51c26.04-26.04 60.67-40.39 97.51-40.39m0-6c-79.47 0-143.89 64.42-143.89 143.89s64.42 143.89 143.89 143.89 143.89-64.42 143.89-143.89-64.42-143.89-143.89-143.89z'/%3E";
      } else {
        buttonCirclePath = "";
      }
      if (this.hasHeader) {
        /*Only using Fold3*/
        if (this.$store.state.isFold3) {
          // containerHeight = "calc(var(--vh, 1vh) * 100 - 7vh)";// 7vh -> nav bar height
          let height = this.$store.state.screenHeight - 48;
          containerHeight = height + "px";
          logoCenter = "1.5%";
        } else {
          containerHeight = "calc(100% - 48px)";
          logoCenter = "1.5%";
        }
      } else {
        containerHeight = "100vh";
        this.isCharging = true;
        logoCenter = "0%";
      }
      return {
        "--height-container": containerHeight,
        "--center-logo": logoCenter,
        "--button-svg":
          "url(\"data:image/svg+xml,%3C%3Fxml version='1.0'  encoding='UTF-8'%3F%3E%3Csvg version='1.1' viewBox='0 0 745.44 745.44' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:" +
          encodeURIComponent(circleNormal) +
          "%3B%7D.cls-2%7Bfill:none%3Bfilter:url(%23drop-shadow-1)%3Bstroke:" +
          encodeURIComponent(circleNormal) +
          "%3Bstroke-miterlimit:10%3Bstroke-width:50px%3B%7D%3C/style%3E%3Cfilter id='drop-shadow-1' filterUnits='userSpaceOnUse'%3E%3CfeOffset dx='0' dy='0'/%3E%3CfeGaussianBlur result='blur' stdDeviation='50'/%3E%3CfeFlood flood-color='" +
          encodeURIComponent(circleNormal) +
          "'" +
          " flood-opacity='.2'/%3E%3CfeComposite in2='blur' operator='in' result='result1'/%3E%3CfeComposite in='SourceGraphic' in2='result1'/%3E%3C/filter%3E%3C/defs%3E%3Ccircle class='cls-2' cx='372.66' cy='372.79' r='222.64'/%3E" +
          buttonCirclePath +
          '%3C/svg%3E")',
        "--logoX": this.logoContainerSize + "px",
      };
    },
    buttonClicked: async function () {
      this.pseudoB2b = this.$store.state.currentPseudo;
      console.log("pseudo enviado " + this.$store.state.currentPseudo);
      if (this.isButton) {
        let user = this.$store.getters["Auth/user"];
        console.log(" user is : ", user);

        /*IF WE ARE IN B2B*/
        if (this.pseudoB2b === "User") {
          // let userID = await this.register({ pseudo: user.name });
          // this.$router.replace({ path: `/user/${userID}` });
          this.$router.replace({ path: `/user/0` });
        } else {
          // let userID = await this.register({ pseudo: this.pseudoB2b });
          // this.$router.replace({ path: `/user/${userID}` });
          this.$router.replace({ path: `/user/0` });
        }
      } else {
        return;
      }
    },
    ...mapActions({
      registerUser: "Auth/registerUser",
    }),
    async register(userName) {
      try {
        console.log("username 2 is ", userName);
        const user = await this.registerUser(userName);
        const userID = user.data.createPlayer.id;
        console.log("user ID is ", userID);
        return userID;
      } catch (error) {
        console.log(error);
      }
      // }
    },
  },
};
</script>

<style scoped>
@import "../assets/css/components/TransitionScreen.css";
</style>
