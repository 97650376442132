<template>
  <div class="accelerationContainer">
    <!-- Button Image -->
    <input
      type="button"
      alt="acceleration control"
      :style="{ width: buttonSize }"
      @mousedown="startAccelerating"
      @mouseup="stopAccelerating"
      @mouseleave="stopAccelerating"
      @touchstart="startAccelerating"
      @touchend="stopAccelerating"
      @touchcancel="stopAccelerating"
      :value="value"
      v-on:input="updateValue($event.target.value)"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      buttonSize: 65, // Button size
      speed: 0, // initial speed
      maxSpeed: 100, // maxSpeed
      timer: null, // to stop aceleration
      mode: "end",
      resetTimout: null,
      value: 0,
    };
  },
  props: {
    isAcceleration: Boolean,
  },
  methods: {
    startAccelerating() {
      this.mode = "move";
      this.speed = this.maxSpeed;
      this.updateValue(Math.round(this.speed));
      // clearTimeout(this.resetTimout);
      console.log("run-Accelerating");
    },

    stopAccelerating() {
      this.mode = "end";
      this.speed = 0;
      this.updateValue(Math.round(this.speed));
      console.log("stop-Accelerating");
    },

    updateValue: function (speed) {
      this.$emit("acelerationInput", {
        // child -> parent
        mode: this.mode,
        value: speed,
      });
      console.log("send-Accelerating    " + this.mode);
    },
  },
};
</script>

<style scoped>
@import "../../../assets/css/controls/Acceleration.css";
</style>
