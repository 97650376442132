import Vue from "vue";
import store from "@/store";
import { DataPacket_Kind } from "livekit-client";

export default {
  steering(mode, data) {
    var VectX;
    var clamping = 0.6;
    var engineRect = 0.7;
    switch (mode) {
      case "move":
        VectX = { direction: -1 * parseInt(data) * clamping * engineRect };
        break;
      case "end":
        VectX = { direction: 0 };
        break;
    }
    sendDataToCar(VectX);
  },
  acceleration(mode, data) {
    var VectY;
    switch (mode) {
      case "move":
        console.log(
          "modifier applied to speed ",
          parseInt(store.state.Control.controls.acceleration.modifier)
        );
        VectY = {
          speed:
            (parseInt(data) / 7) *
            parseInt(store.getters["Control/getAccelerationModifier"]),
        };
        break;
      case "end":
        VectY = { speed: 0 };
        break;
    }
    sendDataToCar(VectY);
  },
};

function sendDataToCar(data) {
  // console.log("Data to be sent:", data);
  Vue.$room.localParticipant.publishData(
    new TextEncoder().encode(JSON.stringify(data)),
    DataPacket_Kind.RELIABLE
  );
}
