<template>
  <div class="header">
    <v-toolbar absolute dense class="ma-0 pa-0 header">
      <!-- <v-toolbar-items>
        <v-btn v-if="!preAccess" text outlined>
          <router-link class="link" to="/ranking" replace>{{
            $t("nav.ranking")
          }}</router-link>
        </v-btn>
      </v-toolbar-items> -->

      <v-toolbar-items>
        <v-btn text outlined>
          <router-link class="link" to="/" replace>{{
            $t("nav.home")
          }}</router-link>
        </v-btn>
      </v-toolbar-items>

      <v-toolbar-items v-if="isB2b == false">
        <v-btn text outlined>
          <router-link class="link" to="/rules" replace>{{
            $t("nav.rules")
          }}</router-link>
        </v-btn>
      </v-toolbar-items>

      <v-spacer></v-spacer>
      <LogOut v-if="isB2b"></LogOut>
      <v-toolbar-items v-if="isB2b == false" class="menuItems">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="100"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-avatar size="36" v-bind="attrs" v-on="on">
              <img class="avatar" alt="Avatar" />
            </v-avatar>
          </template>
          <Options />
        </v-menu>
      </v-toolbar-items>
    </v-toolbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Options from "./OptionMenu.vue";
import LogOut from "@/components/LogOut.vue";
import getEnv from "../config/env.js";

export default {
  name: "Header",
  data() {
    return {
      menu: false,
      LogoutForm: false,
      OptionsForm: false,
      isB2b: false,
      menuContent: "",
    };
  },
  components: {
    Options,
    LogOut,
  },
  mounted: function () {
    this.adaptHeader();
  },
  methods: {
    adaptHeader() {
      if (getEnv("VUE_APP_VROMBR") === "B2B") {
        this.isB2b = true;
      } else {
        this.isB2b = false;
      }
    },
    callLink(routerPath) {
      this.$router.replace({
        path: routerPath,
      });
      console.log("ranking clicked");
    },
  },
  computed: {
    name() {
      return this.$store.carName;
    },
    ...mapState(["isVersusUser", "isAdmin", "isChronoUser", "isSpecial"]),
    preAccess() {
      if (this.$store.carName === "pre_access") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
@import "../assets/css/components/Header.css";
</style>
