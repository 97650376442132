import gql from "graphql-tag";

export const createPlayer = gql`
  mutation CreatePlayer($createPlayer: CreatePlayerInput!) {
    createPlayer(createPlayer: $createPlayer) {
      id
      name
    }
  }
`;
