<template>
  <div class="joystickComponent">
    <boost class="boost" v-if="!isAcceleration"></boost>
    <!-- Joystick SVG -->
    <svg
      class="joystickContainer"
      ref="svg"
      :width="size"
      :height="size"
      @mousemove="handleMouseMove"
      @mouseup="handleMouseUp"
      @touchstart="handleMouseMove"
      @touchend="handleMouseUp"
    >
      <!-- Outside Circle -->
      <circle :cx="center" :cy="center" :r="radius" fill="#ddd" />
      <!-- Little Cirlce (who moves)-->
      <circle
        :cx="innerX"
        :cy="innerY"
        :r="innerRadius"
        fill="#aaa"
        @mousedown="handleMouseDown"
        @touchstart="handleMouseDown"
      />
      :value="value" v-on:input="updateValue($event.target.value)"
    </svg>
  </div>
</template>

<script>
import Boost from "../actions/boost.vue";
export default {
  data() {
    return {
      size: 150, // size SVG
      radius: 70, // outside circle radius
      center: 75, // outside circle center
      innerRadius: 25, // inside cirlce center
      innerX: 75, // posX little circle (starts in the center of the outside circle)
      innerY: 75, // posY little circle (starts in the center of the outside circle)
      isDragging: false, // indicates if the little circle is pressed
      dragStartX: 0, // mouse position in X pressing the litte circle
      dragStartY: 0, // mouse position in Y pressing the litte cirlce
      value: 1,
      mode: "end",
      resetTimout: null,
    };
  },
  components: {
    Boost,
  },
  props: {
    isAcceleration: Boolean,
  },

  methods: {
    //if mouse is moved
    handleMouseMove(event) {
      if (this.isDragging) {
        // we only move the little circle if is dragged
        // get the actual position of the mouse in relation of the SVG
        const rect = this.$refs.svg.getBoundingClientRect();
        const mouseX = event.clientX - rect.left;
        const mouseY = event.clientY - rect.top;
        // get the distance between the outer circle center and the mouse position
        const dx = mouseX - this.center;
        const dy = mouseY - this.center;
        const distance = Math.sqrt(dx * dx + dy * dy);

        // if the distance is smaller to the distance between both circles, you can move the little circle
        // getting the mouse postion as little circle postion
        if (distance < this.radius - this.innerRadius) {
          this.innerX = mouseX;
          this.innerY = mouseY;
          this.MappingPosition(this.innerX, this.innerY);
        } else {
          // If the distance is bigger, move the little circle in the circumference of the big one.
          const angle = Math.atan2(dy, dx);
          this.innerX = (this.radius + 5) * Math.cos(angle) + this.center;
          this.innerY = (this.radius + 5) * Math.sin(angle) + this.center;
          this.MappingPosition(this.innerX, this.innerY);
        }
      }
    },
    //Mapping innerX and InnerY in value -100|100
    MappingPosition(innerX, innerY) {
      // Mapping InnerX
      this.mappedInnerX = Math.round(
        ((innerX - this.size / 2) * 200) / this.size
      );
      // Mapping InnerY
      this.mappedInnerY = Math.round(
        ((innerY - this.size / 2) * -200) / this.size
      );
      //console.log("posX " +this.mappedInnerX + "    posY " + this.mappedInnerY)

      this.mode = "move";
      clearTimeout(this.resetTimout);
      if (!this.isAcceleration) {
        this.updateValue(this.mappedInnerX);
      } else {
        this.updateValue(this.mappedInnerY);
      }
    },
    // if mouse is pressed
    handleMouseDown(event) {
      this.isDragging = true; // the little circle can be dragged
      this.dragStartX = event.clientX; //  get the position on x of the mouse
      this.dragStartY = event.clientY;
    },

    // if mouse is up
    handleMouseUp() {
      // Reset
      this.isDragging = false;
      this.innerX = this.size / 2;
      this.innerY = this.size / 2;

      this.mode = "end";
      this.resetTimout = setTimeout(() => {
        this.updateValue(0);
      }, 0);
    },
    updateValue: function (value) {
      this.value = value;
      this.$emit("joystickInput", {
        // child -> parent
        mode: this.mode,
        value: value,
      });
    },
  },
};
</script>

<style scoped>
@import "../../../assets/css/controls/JoystickC.css";
</style>
