<template>
  <img class="logo" alt="logo Vrombr" />
</template>

<script>
export default {};
</script>

<style scoped>
@import "../assets/css/components/Logo.css";
</style>
