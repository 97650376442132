<template>
  <div class="register">
    <v-container
      class="register"
      fluid
      style="padding-right: 50px; padding-left: 50px"
    >
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container>
          <div class="img-center">
            <img class="logo" src="../assets/images/Logo-Vrombr-B&W.png" />
          </div>
          <v-card class="mx-auto" max-width="344" elevation="0">
            <v-text-field
              class="ma-0 pa-0"
              @keydown.enter.prevent="validate"
              v-model="user.pseudo"
              :label="this.$t('home.pseudo')"
              autocomplete="off"
              required
              :rules="nameRules"
              counter="10"
            ></v-text-field>
          </v-card>
        </v-container>
      </v-form>
      <v-container style="padding-top: 10px; text-align: center">
        <v-btn
          @click="validate"
          @touchstart="validate"
          @touchenter="validate"
          :disabled="user.pseudo === '' || user.pseudo.length > 10"
        >
          {{ $t("home.save") }}
        </v-btn>
      </v-container>
      <div class="errorForm" v-html="error" />
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      valid: false,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      user: {
        pseudo: "",
      },
      error: null,
    };
  },
  methods: {
    ...mapActions({
      registerUser: "Auth/registerUser",
    }),
    async validate() {
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve();
          console.log("resolving ....");
        }, 500);
      });

      this.$refs.form.validate();
      const userID = await this.register();
      if (userID != "") {
        console.log("Saving... " + this.user.pseudo);
        // this.$emit("B2bUserData", userID, this.user.pseudo);
      } else {
        console.log("No registration");
        return;
      }

      // this.$router.replace({ path: `/user/${userID}` });
    },
    async register() {
      try {
        // const user = await this.registerUser(this.user);
        //const userID = user.data.createPlayer.id;
        this.$emit("registered", this.user.pseudo);
        // console.log("user ID is ", userID);
        // return userID;
      } catch (error) {
        console.log(error);
        this.error = error.response.data.error;
      }
    },
  },
};
</script>

<style scoped>
@import "../assets/css/components/RegisterForm.css";
</style>
