<template>
  <div class="boostContainer">
    <img
      id="boost"
      :width="size"
      v-if="boostAvailable"
      src="@/assets/images/control/Boost-On.png"
    />
    <img v-else :width="size" src="@/assets/images/control/Boost-Off.png" />
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: "Boost",
  data() {
    return {
      boostAvailable: true,
      boostValue: 1.25,
      size: 40,
    };
  },
  mounted() {
    this.initTouchEvent();
  },
  methods: {
    initTouchEvent() {
      var el = document.getElementById("boost");
      el.addEventListener("touchstart", this.useBoost, false);
    },
    boostUp() {
      this.boostAvailable = true;
    },
    boostDeactivation() {
      store.commit("Control/setAccelerationModifier", 1);
    },
    useBoost() {
      if (this.boostAvailable == true) {
        store.commit("Control/setAccelerationModifier", this.boostValue);
        setTimeout(this.boostUp, 15000);
        setTimeout(this.boostDeactivation, 3000);
        this.boostAvailable = false;
      } else {
        console.log("boost not enabled");
      }
    },
  },
};
</script>
<style scoped>
@import "../../../assets/css/controls/action/Boost.css";
</style>
